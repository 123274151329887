// import { notification } from 'antd';
import InterviewCanvas from 'components/newCanvas/InterviewCanvas';
import CallbackCanvas from 'components/newCanvas/CallbackCanvas';
import AudioChatCanvas from 'components/newCanvas/AudioChatCanvas';
import firebase from 'firebase/app';
import * as mixpanel from 'mixpanel-browser';
import React, { useEffect, useState } from 'react';
import { hotjar } from 'react-hotjar';
import { connect } from 'react-redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import './App.css';
import AuthAction from './components/auth/AuthAction';
import ConfirmEmail from './components/auth/ConfirmEmail';
import ResetPassword from './components/auth/ResetPassword';
import SignIn from './components/auth/SignIn';
import SignUp from './components/auth/SignUp';
import CanvasDetail from './components/canvas';
import OutreachCanvas from './components/canvas/OutreachCanvas';
import RenderFullScreen from './components/canvas/RenderFullScreen';
import Dashboard from './components/dashboard/Dashboard';
import Invitation from './components/invitation';
import ChromeExtensionVideoRecord from './components/newCanvas/ChromeExtensionVideoRecord';
import ProjectDetails from './components/projects/ProjectDetails';
import PublicChat from './components/public-chat';
// import RecordingModal from './components/recording/RecordingModal';
// import RecordingModal from './components/RecordingExtenstion';
import RecordingModal from './components/recording/RecordingModal';
import AppsumoUsersStats from './components/stats/AppsumoUsers';
import Stats from './components/stats/Stats';
import { askPermission } from './config/fbConfig';
import ColorContext from './context/ColorContext';
import PrivateRoute from './PrivateRoute';
import { projectColors } from './utils/dummyData';
import TermsAndConditions from './terms-and-conditions/TermsAndConditions';

interface IProps {
  auth?: any;
}

const App: React.FC<IProps> = ({ auth }) => {
  // const urlParams = new URLSearchParams(window.location.search);
  // const channelId = urlParams.get('channelId') || '';
  // const pathname = window.location.pathname;
  const [selectedProjectColor, setSelectedProjectColor] = useState(projectColors);

  const handleUpdateColor = (orgColor: any) => {
    setSelectedProjectColor(orgColor);
  };

  const checkOrgProjectIdInSelectedOrgProject = (orgProject: any) => {
    const orgProjectId: string | null = window.localStorage.getItem('selectedProjectId');
    if (orgProjectId) {
      const orgProjectIndex = orgProject.findIndex((op: any) => op.id === orgProjectId);
      if (orgProjectIndex !== -1) {
        return orgProjectIndex;
      } else {
        return -1;
      }
    } else {
      return -1;
    }
  };

  const getOrgProjectColor = () => {
    const firestore = firebase.firestore();
    if (auth.uid) {
      firestore
        .collection('organization')
        .where('member_ids', 'array-contains', auth.uid)
        .onSnapshot((organizationData: any) => {
          if (organizationData && organizationData.docs && organizationData.docs.length) {
            const orgDataDetail = organizationData.docs.map((doc: any) => {
              return {
                ...doc.data(),
                id: doc.id,
              };
            });
            const selectedOrganizationId = window.localStorage.getItem('selectedOrganizationId');
            let selectedOrganizationMatchedIndex = orgDataDetail.findIndex(
              (og: any) => og.id === selectedOrganizationId,
            );
            if (selectedOrganizationMatchedIndex === -1) {
              selectedOrganizationMatchedIndex = 0;
            }
            if (
              orgDataDetail &&
              orgDataDetail[selectedOrganizationMatchedIndex] &&
              orgDataDetail[selectedOrganizationMatchedIndex].projects
            ) {
              orgDataDetail[selectedOrganizationMatchedIndex].projects = orgDataDetail[
                selectedOrganizationMatchedIndex
              ].projects.filter((organizationDataProjects: any) => {
                return organizationDataProjects && organizationDataProjects.isDeleted !== true;
              });
            }
            let localstorageOrgProjectIndex: number = checkOrgProjectIdInSelectedOrgProject(
              orgDataDetail[selectedOrganizationMatchedIndex].projects || [],
            );
            localstorageOrgProjectIndex =
              localstorageOrgProjectIndex !== -1 ? localstorageOrgProjectIndex : 0;
            setSelectedProjectColor(
              orgDataDetail[selectedOrganizationMatchedIndex].projects[localstorageOrgProjectIndex]
                .projectColors,
            );
          }
        });
    }
  };
  useEffect(() => {
    // const ua: string = navigator.userAgent.toLowerCase();
    // if (mobileCheck() && !channelId) {
    //   notification.error({
    //     duration: 0,
    //     message: 'The responsive view for this app will be launched very soon.',
    //   });
    // }
    // else if (ua.indexOf('safari') != -1) {
    //   // if (ua.indexOf('chrome') === -1 && !channelId) {
    //   //   notification.error({
    //   //     duration: 0,
    //   //     message:
    //   //       'This app is not currently compatible for safari browser. For best resolution please use chrome browser.',
    //   //   });
    //   // }
    // }

    mixpanel.init('0aee45e41d5dbae58479359ff526b86b'); // Todo: move it to .env file
  }, []);

  useEffect(() => {
    // const isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
    // if (!isChrome && !mobileCheck()) {
    //   notification.error({
    //     duration: 0,
    //     message:
    //       'This app is currently compatible for chrome browser. For best resolution please use chrome browser.',
    //   });
    // }
    askPermission();
    getOrgProjectColor();
    hotjar.initialize(1803888, 6);

    // this will disable zoom on the IOS devices
    document.documentElement.addEventListener(
      'touchstart',
      (event) => {
        if (event.touches.length > 1) {
          event.preventDefault();
        }
      },
      false,
    );
  }, [auth]);
  hotjar.initialize(1601758, 6);

  // this needs to be replaced with the public chat with FH chat window
  // useEffect(() => {
  //   // @ts-ignore
  //   window.$crisp = [];
  //   // @ts-ignore
  //   window.CRISP_WEBSITE_ID = 'cedf8634-e7bd-45f6-a876-340432ffc778';

  //   // tslint:disable-next-line
  //   (function () {
  //     const d = document;
  //     const s = d.createElement('script');

  //     s.src = 'https://client.crisp.chat/l.js';
  //     // @ts-ignore
  //     s.async = 1;
  //     d.getElementsByTagName('head')[0].appendChild(s);
  //   })();
  // }, []);

  // if (
  //   mobileCheck() &&
  //   !channelId &&
  //   !pathname.includes('/record/') &&
  //   !pathname.includes('/public-chat') &&
  //   !pathname.includes('/signUp') &&
  //   !pathname.includes('/signIn')
  // ) {
  //   return (
  //     <Alert
  //       style={{ margin: '20px' }}
  //       message='This app is currently not compatible for mobile devices. Please open it in a laptop or desktop computer using Chrome browser.'
  //       type='error'
  //     />
  //   );
  // } else {
    console.log("call 191")
  return (
    <BrowserRouter>
      <div className={`App`}>
        {' '}
        <React.Fragment>
          {/* {(!mobileCheck() || channelId) && ( */}
          <ColorContext.Provider value={{ theme: selectedProjectColor, handleUpdateColor }}>
            <Switch>
              <Route path="/terms-and-conditions" component={TermsAndConditions} />
              <Route path='/signIn' component={SignIn} />
              <PrivateRoute exact={true} path='/' component={Dashboard} />
              <PrivateRoute exact={true} path='/old' component={Dashboard} />
              <PrivateRoute exact={true} path='/pt/:id' component={Dashboard} />
              <PrivateRoute exact={true} path='/pk/:id' component={Dashboard} />
              <PrivateRoute exact={true} path='/stats' component={Stats} />
              <PrivateRoute exact={true} path='/appsumo-user-stats' component={AppsumoUsersStats} />
              <Route path='/project/:id/' component={ProjectDetails} />
              <Route path='/invitation' component={Invitation} />
              <Route path='/interview/:projectId/:workflowId' component={InterviewCanvas} />
              <Route path='/callback/:projectId/:workflowId' component={CallbackCanvas} />
              <Route path='/interview/hKyDrhnVQsZVWSnnobMC' component={InterviewCanvas} />
              <Route path='/audio-chat/:projectId/:workflowId' component={AudioChatCanvas} />
              <Route path='/signUp/sumo/:appSumoId?' component={SignUp} />
              <Route path='/signUp/' component={SignUp} />
              <Route path='/authAction' component={AuthAction} />
              <Route path='/reset-password' component={ResetPassword} />
              <Route path='/public-chat' component={PublicChat} />
              <Route path='/chat/:id' component={OutreachCanvas} />
              <Route path='/public/chat/:id' component={OutreachCanvas} />
              <Route path='/iframe/:id' component={CanvasDetail} />
              <Route path='/text/:id' component={CanvasDetail} />
              <Route path='/whiteboard/:id' component={CanvasDetail} />
              <Route path='/image/:id' component={CanvasDetail} />
              <Route path='/pdf/:id' component={CanvasDetail} />
              <Route path='/audio/:id' component={CanvasDetail} />
              <Route path='/website/:id' component={CanvasDetail} />
              <Route path='/c/:id' component={CanvasDetail} />
              <Route path='/confirmEmail' component={ConfirmEmail} />
              <Route exact path='/canvas-detail/:id' component={RenderFullScreen} />
              <PrivateRoute path='/slack/:recordType' component={RecordingModal} />
              <Route path='/record/:recordType' component={RecordingModal} />
              <Route path='/chrome-extension-video-record' component={ChromeExtensionVideoRecord} />
            </Switch>
          </ColorContext.Provider>
          {/* )} */}
        </React.Fragment>{' '}
      </div>
    </BrowserRouter>
  );
  // }
};

const mapStateToProps = (state: any) => {
  return {
    auth: state.firebase.auth,
    // firebaseSate: state.firebase,
  };
};

export default connect(
  mapStateToProps,
  null,
  // @ts-ignore
)(App);
